.camera-detail-card-container {
  display: flex;
  background: #f7f7f7;
  width: 100%;
  height: 45px;
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
    .icon {
      display: flex;
      width: 12px;
      height: 12px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .camera-name-container {
    display: flex;
    align-items: center;
    width: 532px;
    height: 100%;
    .camera-name {
      font-family: 'Prompt', sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
      margin-right: 30px;
    }
    .camera-name-status {
      font-size: 14px;
    }
  }
  .camera-detail-btn {
    margin-left: 20px;
    font-size: 14px;
  }
  .camera-detail-option-container {
    width: 490px;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .camera-detail-remove-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 100%;
  }
}
